.projectsRoot {
  display: flex;
  flex-wrap: wrap;
  background-color: #8c91ff;
  height: 100%;
  align-items: flex-start;
  justify-content: center;
}

.projectContainerNew {
  display: flex;
  flex: 1 1 500px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  margin: 10px;
  background-color: white;
  max-width: 1200px;
  /* min-width: 500px; */
  height: fit-content;
  flex-wrap: wrap;
}

.projectCardIconCont {
  flex: 1 0 150px;
  display: flex;
  justify-content: center;
}

.projectDataN {
  display: flex;
  flex: 6 1 220px;
  flex-direction: column;
  padding: 10px 5px;
}

.projectName {
  font-size: 20px;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

.projectNameDivN {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.projectDescription1 {
  /* font-size: 14px; */
  color: #0000008a;
  /* flex: 1; */
  /* margin-top: 2px; */
  /* margin-bottom: 2px; */
  min-height: 60px;
}

.projectActions {
  display: flex;
  justify-content: flex-end;
}

.projectOwner {
  font-size: 12px;
  color: #0000008a;
  flex: 1;
  margin: 0;
}

.projectTextColor {
  color: #0000008a;
}

.projectProps {
  margin: 0;
  display: flex;
  justify-content: space-between;
}

.projectType {
  font-size: 12px;
  color: #0000008a;
  flex: 1;
  margin: 0;
}

.projectStatus {
  font-size: 12px;
  color: #0000008a;
  flex: 1;
  margin: 0;
  text-align: right;
  padding-right: 10px;
}

.MuiDialog-paper {
  height: 80%;
}

.dialogTitle {
  display: flex;
  justify-content: space-between;
  padding: 8px 12px !important;
  border-bottom: 0.5px solid #ccc;
}

.dialogTitle h6 {
  align-self: center;
}

.closeButton {
  position: "absolute";
  align-self: center;
  height: 50px;
  width: 50px;
}

.dialogContent {
  margin-top: 0px !important;
  min-height: 50%;
  height: 10% !important;
}

.abasDialog {
  display: flex;
}

.abasDialog li {
  display: inline;
  margin-right: 15px;
}

.tabsContainer {
}
