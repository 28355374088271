.contAudio {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 3px;
  background-color: rgb(200, 200, 200);
  
  border-radius: 10px;
  padding: 3px;
}
.contAudio .audio-react-recorder {
  flex: 1;
}
.contAudio .audio-react-recorder .audio-react-recorder__canvas {
  width: 100%;
  height: 40px;
}
