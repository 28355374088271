.containerProfile{
    display: flex;
}

@media screen and (max-width: 700px) {
    .containerProfile {
      display: block !important;
    }
    #fields{
        margin-top: 5px;
    }
  }