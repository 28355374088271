.RenderUser {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    margin: 5px;
    flex: 1 1 500px;
}

.UsersList {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 250px;
    margin: 15px 0px;
}