.rootTableGridPag {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.rootTableGridContant {
  flex: 1;
  position: relative;

  overflow: auto;
}
.rootTableGrid {
  position: relative;
  display: grid;
}
.rowTableHeaderGrid {
  display: contents;
}

.rowTableGrid {
  display: contents;
  cursor: pointer;
}

.celTableH {
  padding: 5px;
  margin: 2px;
  display: flex;
}

.celTableGrid {
  padding: 5px;
  margin: 2px;
  border-bottom: 1px;
  border-bottom-color: #e0e0e0;
  border-bottom-style: solid;
  min-height: 56px;
  display: flex;
  align-items: center;
}
.celTableGridInner {
  flex: 1;
}

.celTableGrid .celTableTitleGrid {
  display: none;
  flex: 1;
}
.rowTableGrid .tableGridShowOverRow {
  visibility: hidden;
}

.rowTableGrid:hover .tableGridShowOverRow {
  visibility: visible;
}
@media only screen and (max-width: 420px) {
  .rootTableGrid  {
    display: flex;
    flex-direction: column;
  }
  .celTableH {
    display: none;
  }
  .celTableGrid {
    display: block;
  }
  .celTableGrid .celTableTitleGrid {
    display: block;
  }
  .rowTableGrid {
    display: block;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
  }
  .hideMobile {
    display: none !important;
  }
}
