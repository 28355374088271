.StyleRelatorioEditor-comp {
  flex: 1 0 300px;
  max-width: 300px;
  border: 1px solid #ccc;
  padding: 5px;
  margin-top: 5px;
}
.StyleRelatorioEditor-comp label {
  color: black;
  font-size: 12px;
  flex: 1;
  margin-bottom: 3px;
}
.StyleRelatorioEditor-row {
  display: flex;
  gap: 5px;
  justify-content: space-between;
}

.StyleRelatorioEditor-buttonSelected {
  background-color: lightcoral;
}

.StyleRelatorioEditor-cell50 {
  display: flex;
  gap: 2px;
  align-items: center;
  flex: 1;
}
.StyleRelatorioEditor-cell50 label {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  flex: 1;
}

.StyleRelatorioEditor-cell50 input {
  max-width: 50px;
}

.StyleRelatorioEditor-ShowStyle-container {
}

.StyleRelatorioEditor-ShowStyle-containerTitle {
  display: flex;
  margin-top: 5px;
  padding-top: 3px;
  border-top: black solid 1px;
}
.StyleRelatorioEditor-ShowStyle-containerTitle:hover
  .StyleRelatorioEditor-ShowStyle-containerTitleHide {
  visibility: visible;
}
.StyleRelatorioEditor-ShowStyle-containerTitleHide {
  visibility: hidden;
}

.StyleRelatorioEditor-ShowStyle-containerStyles {
  margin-top: 5px;
}

.StyleRelatorioEditor-dialog-appBar {
  position: relative;
  background-color: cadetblue;
  display: flex;
  
  align-items: center;
  padding-left: 15px;
}

.StyleRelatorioEditor-dialog-title {
  margin-left: 5px; 
  flex: 1;
}

.StyleRelatorioEditor-dialog-root {
  flex-grow: 1;  
  display: flex;
  height: 77px;
  padding: 5px;
  
}
