.overTitle {
    position: absolute;
    left: 6px;
    top: 0px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px;
    background-color: #ccc;
    max-width: 50%;
    /* padding: 3px 7px; */
}

.overActions {
    position: absolute;
    right: 6px;
    top: 0px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 3px;
    background-color: #ccc;
    z-index: 1000;
    opacity: 0.8;
    /* padding: 3px 7px; */
}
.overActionsParent {
    position: absolute;
    right: 6px;
    top: 0px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 3px;
    background-color: rgb(126, 108, 228);
    z-index: 1000;
    opacity: 0.8;
    /* padding: 3px 7px; */
}
.overActionsParentComFilhos {
    position: absolute;
    right: 140px;
    top: 0px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 3px;
    background-color: rgb(126, 108, 228);
    z-index: 1000;
    opacity: 0.8;
    /* padding: 3px 7px; */
}


.notOverContainer {
    /* display: contents !important; */
    position: relative;
    flex: 1;
}

.overContainer {
    position: relative;
    /* padding: 23px 3px 0px 3px; */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px;
    flex: 1;
   
}

.overContainerChildren {
    position: relative;
    /* padding: 36px 3px 2px 3px; */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px;
    flex: 1;
}

.actionIconOver {
    height: 14px !important;
    margin: 1px;
}

.actionIconOver:hover {
    color: #f00;
}