.VisualizadorPdf-container {
  background-color: #ccc;
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 5px;
  overflow: scroll;
  position: relative;
}
.VisualizadorPdf-container div {
  
 
  
  padding: 5px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

/* 


textAlign: '-webkit-center',

overflow: 'scroll',
position: 'relative' */
