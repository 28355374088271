.ContainerMenuRoot {
  display: flex;
  height: 100%;
  width: 100%;
}

.ContainerMenuForm {
  background-color: blue;
  flex: 1;
}

.actionsAppBar {
  display: flex;
  gap: 10px;
}
.actionsBt {
  margin-left: 5px;
}

.topbarbuttomaction {
padding: 5px !important;
}

@media only screen and (max-width: 420px) {
  .actionsAppBar button {
    font-size: 10px;
  }
  .topTollBar{
    padding-right: 5px !important;
  }

 
}
