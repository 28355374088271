#root, .app, html, body, .PageBox{
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100%;
}
.loader{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
}
