.EditorContainer {
    display: flex;
    flex-flow: column;
    height: 100%;
    box-sizing: border-box;
    border-radius: 3px;
    box-shadow: 1px 1px 20px rgba(20, 20, 20, 0.27);
}

.ContainerCode {
    min-height: 0;
    flex: 1;
    box-sizing: border-box;
    overflow: auto;
}

.LiveWrapper {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;
    flex: 1;
}

.StyledEditor {
    background: #42374a;
    font-family: 'Source Code Pro', monospace;
    font-size: 14px;
    flex: 1;
}

.StyledPreview {
    position: relative;
    flex: 1;
    display: flex;
}

.StyledError {
    display: block;
    padding: 8px;
    background: #ff5555;
    color: #f8f8f2;
    white-space: pre-wrap;
    text-align: left;
    font-size: 0.9em;
    font-family: 'Source Code Pro', monospace;
    max-height: 200px;
    overflow: auto;
}

.ButtonEditor {
    display: contents !important;
    /* //  background: rgb(255, 0, 0);   */
}

.ButtonEditor:hover {
    /* background: rgb(7, 184, 101); */
}

.PaneStyleTeste1h {
    display: flex;
}
.PaneStyleTeste2h {
    display: flex;    
}