.ContainerMenuRoot {
    display: flex;
    height: 100%;
    width: 100%;
}

.ContainerMenuItens{
   background-color: aquamarine;
   width: 240px;
}
.ContainerMenuForm {
  background-color: blue;
  flex: 1;
}