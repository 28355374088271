.rootTable {
    position: relative;
    overflow: auto;
}

.rowTable {
    display: flex;
    width: 100%;
    border-bottom: 1px;
    border-bottom-color: #e0e0e0;
    border-bottom-style: solid;
    cursor: pointer;
     /* background-color: blue; */
}

.celTable {
    padding: 5px;
    /* background-color: #ff0; */
    margin: 2px;
}