.containerRegistro {
    display: flex;
    flex-flow: column;
    align-items: center;
}

.containerItem {
    padding-left: 5px;
    padding-right: 5px;
    flex: 1;
    display: flex;
    min-width: 280px;
    justify-content: center;
}

.linkText {
    cursor: pointer;
}