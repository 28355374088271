.nodeObject {
  background: #eee;
  pointer-events: all;
  opacity: 1;
  border: 1px solid rgb(119, 119, 119);
  padding: 10px;
  border-radius: 5px;
  /* box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2); */
}

.nodeTelaFuncionalidade {
  background: rgb(218, 208, 253);
  pointer-events: all;
  opacity: 1;
  border: 1px solid rgb(119, 119, 119);
  padding: 10px;
  border-radius: 5px;
}
.nodeObject:hover .nodeRemover {
  visibility: visible;
}
.nodeTelaFuncionalidade:hover .nodeRemover {
  visibility: visible;
}
.nodeRemover {
  visibility: hidden;
  position: absolute;
  top: 0;
  right: 0;
  margin: 2px;
  padding: 5px;
  font-weight: 700;
  cursor: pointer;
  z-index: 1000;
}
.rowData{

}
.rowData:hover .rowRemover {
  visibility: visible;
}
.rowRemover {
  visibility: hidden;
  position: absolute;
  left: -22px;
  margin: 2px;
  padding: 5px;
  font-weight: 700;
  cursor: pointer;
  z-index: 1000;
}

.reactflow-wrapper {
  flex: 1;
}
.edgebutton {
}

.edgebutton-foreignobject {
  opacity: 0%;
}

.edgebutton-foreignobject:hover {
  opacity: 100%;
}
