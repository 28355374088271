.projectsRootCopia {
    /* display: flex;
    flex-wrap: wrap;
    background-color: cadetblue;
    height: 100%;
    align-items: flex-start;
    justify-content: center; */
    display: flex;
    flex: 1;
}

.projectContainer {
    display: flex;
    flex: 1;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px;
    margin: 10px;
    background-color: white;
    max-width: 1200px;
}

.projectIcon {
    width: 150px;
    height: auto;
}

.projectData {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 10px 5px;
    margin: 0 auto;
}

.projectName {
    font-size: 20px;
    color: #333;
}

.projectDescription {
    font-size: 14px;
    color: #0000008a;
    flex: 1;
}

.projectActions {
    display: flex;
    justify-content: flex-end;
}