.kamban{
    /* background-color: brown; */
    display: flex;
    height: 100%;
    flex: 1 1 350px;

}
.coluna{
    background-color: aqua;
    min-width: 220px;
}


.item{
    /* background-color: blueviolet; */
    
    min-height: 100px;
}
.tituloColunas{
   /* color: chartreuse; */
}