.OKAppLoadingRoot {
    position: absolute;
    background-color: #2f2f2f;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}

.OKAppLoadingContent {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.OKAppLoadingContent img {
    height: 150px;
}

.loading-container {
    animation: rotate 5s linear infinite;
    height: 150px;
    width: 200px;
    transform-origin: bottom center;
    position: absolute;
    top: -50%;
    
}

.circle {
    animation: grow 3s linear infinite;
    background-color: #575fff;
    border-radius: 50%;
    display: inline-block;
    height: 40px;
    width: 40px;
    transform: scale(0);
    position: relative;
    margin-right: 10px;
    top: 25px;
}

.circle:nth-of-type(2) {
    animation-delay: .75s;
    background-color: #ef9c32;
    top: 0px;
}

.circle:nth-of-type(3) {
    animation-delay: 1.5s;
    top: 0px;
}

.circle:nth-of-type(4) {
    animation-delay: 2.25s;
    background-color: #ef9c32;
}

@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}

@keyframes grow {
    50% {
        transform: scale(1);
    }
}