.RegioesDnD-conteinerRoot {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.RegioesDnD-conteinerTitle {
  background-color: cornflowerblue;
  padding: 5px;
}

.RegioesDnD-conteinerGrid {
  background-color: #c6c6c6;
  padding: 5px;
  flex: 1;
  display: grid;
}
.RegioesDnD-conteinerRegion {
  background-color: #fff;
  /* margin: 10px; */
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.RegioesDnD-conteinerRegion-titleConteiner {
  display: flex;
  align-items: center;
  background-color: cornflowerblue;
  padding: 5px;
}

.RegioesDnD-conteinerRegion-titleConteiner p {
  flex: 1 0 auto;
  font-weight: 700;
}

.RegioesDnD-conteinerRegion-titleConteiner button {
  visibility: hidden;
  margin-bottom: 5px;
}
.RegioesDnD-conteinerRegion:hover button {
  visibility: visible;
}

.RegioesDnD-conteinerRegion-itensConteiner {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px;
  min-height: 150px;
  min-width: 200px;
}
.RegioesDnD-conteinerItemLista {
  position: relative;
  background-color: #fff;
  padding: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.RegioesDnD-conteinerItemLista:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.RegioesDnD-conteinerItemLista:hover .RegioesDnD-conteinerItemLista-Delete {
  visibility: visible;
}

.RegioesDnD-conteinerItemLista-Delete {
  visibility: hidden;
  position: absolute;
  right: 5px;
  top: 10px;
  cursor: pointer;
  z-index: 100;
}
