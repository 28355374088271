.root {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.homeContainer {
  max-width: 1140px;
  margin: 0 auto;
}

.limiteWight {
  max-width: 1140px;
  margin: 0 auto;
  padding-left: 35px;
  padding-right: 35px;
}

.top {
  background-color: #ff4400;
}

.main {
  background-color: #5547f8;
  display: flex;
  flex-wrap: wrap;
  color: white;
  width: 100%;
  height: 550px;
  background-image: url("nuvem.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position-x: 815px;
  background-position-y: 65px;
}

.mainCont {
  /* flex: 1 1 350px; */
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  max-width: 450px;
}

.mainImage {
  /* flex: 1 0 350px; */
  padding-top: 35px;
  padding-bottom: 35px;
}

.mainSpace {
  flex: 1 1 350px;
  padding: 35px;
}

.responsiveImage {
  width: 100%;
  height: auto;
}

.h1 {
  font-size: 35px;
}

.mainDesc {
  font-size: 18px;
  margin-top: 30px;
  margin-bottom: 35px;
}

.mainBtTeste {
  background-color: #ff4400;
  border-radius: 28px;
  border: 1px solid #ff9100;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 17px;
  padding: 16px 31px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #381001;
}

.mainBtTeste:hover {
  background-color: #ff9100;
}

.mainBtTeste:active {
  position: relative;
  top: 1px;
}

.mainBtMais {
}

.container1 {
  display: flex;
  flex-wrap: wrap;
  background-image: linear-gradient(to right, #cc613c, #f7ed48);
  padding-top: 45px;
  padding-bottom: 45px;
}

.containerCont {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.containerCont2 {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  flex-direction: column;
}
.cardQuem {
  flex: 1 1 250px;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.6);
  transition: 0.3s;
  /* border-radius: 5px; */
  margin: 15px;
}

.cardQuemCont {
  padding: 2px 16px;
}

.cardQuem:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.6);
}

.container2 {
  display: flex;
  flex-wrap: wrap;
  padding-top: 35px;
  padding-bottom: 35px;
}

.container2Content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 55px;
}

.container2Image {
  margin-top: -235px;
  max-width: 650px;
}

.rodape {
  display: flex;
  flex-wrap: wrap;
  padding-top: 15px;
  padding-bottom: 5px;
  background-color: #5547f8;
  color: white;
}

.beta {
  font-size: xx-large;
  background: red;
  color: white;
  padding: 5px;
  text-transform: uppercase;
  position: absolute;
  top: 130px;
  left: 3px;
  z-index: 2;
  transform: rotate(-45deg);
}

.socialLinks {
  align-self: center;
}

.socialButton {
  margin-left: 10px;
}
.whatsFloatButton {
  background: #34af23;
  border-radius: 50%;
  position: fixed;
  right: 15px;
  bottom: 15px;
  height: 55px;
  width: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 36px;
  cursor: pointer;
}


@media only screen and (max-width: 900px) {
  .beta {
    left: unset;
    transform: rotate(18deg);
    right: 3px;
    font-size: large;
    top: 85px;
  }
}

.container3 {
  display: flex;
  flex-wrap: wrap;
  padding-top: 35px;
  padding-bottom: 35px;
  background-color: #5547f8;
  color: white;
}
