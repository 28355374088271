
.msgPlano {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 10px 5px;
    margin: 0 auto;
}

.msgTitulo {
    font-size: 20px;
    color: #333;
}

.containerAbas{
    display: flex;
}

.tab{
    width: 100%;
}
