.dialogPesquisaContainer2 {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 55px 1fr;
  overflow: hidden;
  height: 100%;
}

.dialogPesquisaTitle2 {
  padding: 10px;
  padding-right: 20px;
  display: flex;
  align-items: center;
}

.dialogPesquisaBody2 {
  display: grid;
  grid-template-columns: 240px 1fr;
  overflow: hidden;
}

.dialogPesquisaBodySemFilter{
  display: grid;
  grid-template-columns: 1fr;
  overflow: hidden;
}
.dialogPesquisaFilter2 {
  border-right: 1px solid rgba(224, 224, 224, 1);
  padding-left: 10px;
  padding-right: 10px;
  background-color: azure;
}
.dialogPesquisaFilterMobile {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  padding-left: 10px;
  padding-right: 10px;
  background-color: azure;
  display: none;
}
.dialogMobileSearchButtom {
  display: none !important;
}
.dialogPesquisaResultsActions2 {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}

.dialogPesquisaResults2 {
  display: grid;
  overflow: hidden;
  position: relative;
  height: 100%;
}

.dialogPesquisaResults2 .rootTablePag {
  overflow: auto;
}

.dialogPesquisaResults2 .celTableH {
  background-color: white;
  position: sticky;
  top: 0%;
}
.dialogPesquisaActions2 {
  flex: 0;
  border-top: 1px solid rgba(224, 224, 224, 1);
  height: 55px;
  padding: 10px;
  display: flex;
  align-items: center;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (max-width: 960px) {
  .dialogPesquisaFilter2 {
    display: none;
  }
  .dialogPesquisaBody2 {
    grid-template-columns: 1fr;
  }
  .dialogPesquisaFilterMobile {
    display: block;
  }
  .dialogMobileSearchButtom {
    display: flex !important;
  }

 

}
