.UploadButtonControl {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.UploadButtonMagError {
  font-size: 12px;
  color: #ff5964;
}

.AnexoItemCard {
  padding: 5px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}
.AnexoItemDados {  
  display: flex;
  flex-direction: column;
}
.AnexoItemMiniatura {
  position: relative;
  flex: 1 1 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 350px;
}
.AnexoItemMiniatura img {
  width: auto;
  max-height: 250px;
  max-width: 250px;
  cursor: pointer;
}
.AnexoItemMiniaturaVideo {
  /* width: auto;
  max-height: 250px;
  max-width: 250px;
  cursor: pointer; */
}
.AnexoItemMiniaturaAudio{
  
}
.AnexoItemAbrir {
  flex: 0;
}
.AnexoItemDadosRow {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.AnexoContButtonOnly {
  display: flex;
  justify-content: center;
}
