.OkStyleEditor-container {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.OkStyleEditor-comp {
  flex: 1 0 300px;
  max-width: 300px;
  border: 1px solid #ccc;
  padding: 5px;
}
.OkStyleEditor-comp label {
  color: black;
  font-size: 12px;
  flex: 1;
  margin-bottom: 3px;
}
.OkStyleEditor-row {
  display: flex;
  gap: 5px;
  justify-content: space-between;
}

.OkStyleEditor-cell {
  display: flex;
  gap: 5px;
}

.OkStyleEditor-cell50 {
  display: flex;
  gap: 2px;
  align-items: center;
  flex: 1;
}
.OkStyleEditor-cell50 label {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  flex: 1;
}

.OkStyleEditor-cell50 input {
  max-width: 50px;
}

.OkStyleEditor-buttonSelected {
  background-color: lightcoral;
}
