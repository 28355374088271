.containerLogin {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: "100%";
}

.containerItem {
  width: "100%";
  padding-left: 5px;
  padding-right: 5px;
  flex: 1;
  display: flex;
  min-width: 280px;
  justify-content: center;
}
